import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { globalHistory } from "@reach/router";
import Scroll from "react-scroll";
import styled from "styled-components";
import { ImageTextCarousel } from "../components/pageBlocks/imageTextCarousel";
import { BlockZoneFragment, MetaCardFragment } from "../GraphQl/queryFragments"; //eslint-disable-line
import { useAppContext } from "../state";
import SEO from "../components/global/seo";
import { FooterLandingPage } from "../components/nav/footerLandingPage";
import { BlockZone } from "../components/pageBlocks/blockZone";
import { useBlogsQuery } from "../GraphQl/useBlogsQuery";

const StyledLandingPage = styled.div`
  --textColor: #3f3f3f;
  --headingColor: #a77e52;
`;

const LandingPage = ({ data: { page } }) => {
  const { metaDescription, metaTitle, metaKeywords, metaImage } =
    page.meta || {};
  const { pageBlocks, footerLogo, footerWebsiteUrl, footerWebsiteText } =
    page.main || {};
  const { setHiddenHeaderFooter } = useAppContext();
  const { nodes } = useBlogsQuery();

  const scroll = Scroll.animateScroll;
  const search = globalHistory.location.search;
  const target = parseInt(search.split("#")[1]);

  useEffect(() => {
    if (target) {
      scroll.scrollTo(target);
    }
    setHiddenHeaderFooter(true);
  }, [target, scroll, setHiddenHeaderFooter]);

  const spacing = {
    padTop: "170",
    padBot: "170",
  };

  const link = {
    page: {
      main: {
        slug: {
          current: "blogs",
        },
      },
    },
    linkText: "View all",
  };

  return (
    <StyledLandingPage>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaImage={metaImage}
      />
      {pageBlocks && <BlockZone zoneBlocks={pageBlocks} />}
      <ImageTextCarousel
        heading="More Blogs"
        spacing={spacing}
        items={nodes}
        isBgImage={false}
        link={link}
      />
      <FooterLandingPage
        footerLogo={footerLogo}
        footerWebsiteUrl={footerWebsiteUrl}
        footerWebsiteText={footerWebsiteText}
      />
    </StyledLandingPage>
  );
};

export default LandingPage;

export const LandingPageQuery = graphql`
  query landingPageQuery($slug: String!) {
    page: sanityLandingPage(main: { slug: { current: { eq: $slug } } }) {
      main {
        footerLogo {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        footerWebsiteUrl
        footerWebsiteText
        pageBlocks {
          ...BlockZoneFields
        }
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`;
