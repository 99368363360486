import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { GridContainer } from "../common";

const StyledFooter = styled(GridContainer)`
  background: var(--bgDark);
  color: white;
  grid-template-columns: repeat(14, 1fr);
  grid-row: 1;
`;
const FooterInner = styled.div`
  grid-column: 2 / span 12;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  section {
    margin-top: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin: 0 3px;
      list-style: none;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
  @media screen and (min-width: 767px) {
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    .gatsby-image-wrapper {
      width: 40%;
      max-width: 340px;
    }
    section {
      text-align: right;
      margin-top: 0px;
      > a {
        font-size: 20px;
      }
    }
    ul {
      font-size: 14px;
      display: flex;
      li {
        margin: 0 0 0 6px;
      }
    }
  }
`;

export const FooterLandingPage = ({
  footerWebsiteText,
  footerWebsiteUrl,
  footerLogo,
}) => {
  return (
    <StyledFooter>
      <FooterInner>
        {footerLogo && <Img fluid={footerLogo.asset.fluid} />}
        <section>
          {footerWebsiteUrl && (
            <>
              {footerWebsiteText ? (
                <a
                  href={`${footerWebsiteUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {footerWebsiteText}
                </a>
              ) : (
                <a
                  href={`${footerWebsiteUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {footerWebsiteUrl}
                </a>
              )}
            </>
          )}
          <ul>
            <li>
              <Link to="privacy-policy">Privacy Policy</Link>
            </li>
            <li>|</li>
            <li>© 2021 Aratu Forests Limited + eLandNZ Ltd</li>
          </ul>
        </section>
      </FooterInner>
    </StyledFooter>
  );
};
